import React from "react";

import Layout from "../components/layout";
import Container from "../components/container";

const PrivacyPolicy = () => (
  <Layout title="Privacy policy - Supersheets">
    <Container>
      <div>
        <div className="max-w-2xl m-auto mb-16">
          <h1 className="font-serif text-center font-light text-3xl md:font-medium p-4 md:p-8">
            Supersheets Ltd Privacy Policy
          </h1>
          Supersheets respects your privacy and is committed to protecting your
          personal data. This privacy policy will tell you how we look after
          your personal data when you visit our website (regardless of where you
          visit it from) and tell you about your privacy rights and how the law
          protects you.
          <br />
          <br />
          Supersheets Ltd is a private company registered in England and Wales
          with company number 13566223. Supersheets Ltd is the controller and
          responsible for your personal data ("Supersheets", "we", "us" or "our"
          in this privacy policy).
          <Section>Topics:</Section>
          <List>
            <li>What data do we collect?</li>
            <li>How do we collect your data?</li>
            <li>How will we use your data?</li>
            <li>Sharing your data</li>
            <li>How do we store your data?</li>
            <li>Marketing</li>
            <li>What are your data protection rights?</li>
            <li>What are cookies?</li>
            <li>How do we use cookies?</li>
            <li>How to manage your cookies</li>
            <li>Privacy policies of other websites</li>
            <li>Changes to our privacy policy</li>
            <li>How to contact us</li>
            <li>How to contact the appropriate authorities</li>
          </List>
          <Section>What data do we collect?</Section>
          We collect the following data:
          <List>
            <li>
              Personal identification information (e.g. name, email address,
              website).
            </li>
          </List>
          <Section>How do we collect your data?</Section>
          You directly provide us with all of the data we collect. We collect
          data and process data when you:
          <List>
            <li>Fill out a sign up to register interest in our services.</li>
          </List>
          <Section>How will we use your data?</Section>
          We collect your data so that we can:
          <List>
            <li>
              Contact you to set up an account and contact you with regards to
              our products and services.
            </li>
            <li>
              Manage our relationship with you, including notifying you about
              changes to our privacy policy and asking you to participate in a
              survey or leave a review.
            </li>
          </List>
          <Section>Sharing your data.</Section>
          We share your personal data with carefully selected service providers
          who help us with the day-to-day running of our business (such as our
          marketing platform).
          <Section>How do we store your data?</Section>
          We store your data on our secure servers and with authorised third
          parties.
          <br />
          <br />
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions, and they are subject
          to a duty of confidentiality.
          <br />
          <br />
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
          <Section>Marketing</Section>
          We would like to send you information about products and services of
          ours that we think you might like. If you have agreed to receive
          marketing, you may always opt out at a later date. You have the right
          at any time to stop us from contacting you for marketing purposes.
          <Section>What are your data protection rights?</Section>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
          <List>
            <li>
              <span className="font-bold">The right to access</span> - You have
              the right to request us for copies of your personal data. We may
              charge you a small fee for this service.
            </li>
            <li>
              <span className="font-bold">The right to rectification</span>- You
              have the right to request that we correct any information you
              believe is inaccurate. You also havethe right to request us to
              complete the information you believeis incomplete.
            </li>
            <li>
              <span className="font-bold">The right to erasure</span>- You have
              the right to request that we erase your personal data, under
              certain conditions.
            </li>
            <li>
              <span className="font-bold">
                The right to restrict processing
              </span>
              - You have the right to request that we restrict the processing of
              your personal data, under certain conditions.
            </li>
            <li>
              <span className="font-bold">
                The right to object to processing
              </span>
              - You have the right to object to our processing of your personal
              data, under certain conditions.
            </li>
            <li>
              <span className="font-bold">The right to data portability</span>-
              You have the right to request that we transfer the data that we
              have collected to another organization, or directly to you, under
              certain conditions. If you make a request, we have one month to
              respond to you. If you would like to exercise any of these rights,
              please contact us by email.
            </li>
          </List>
          <Section>Cookies</Section>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology. For further information, visit
          allaboutcookies.org.
          <Section>How do we use cookies?</Section>
          We do not use cookies of any kind at this time.
          <Section>How to manage cookies</Section>
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, in a few
          cases, some of our website features may not function as a result.
          <Section>Privacy policies of other websites</Section>
          The Supersheets website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
          <Section>Changes to our privacy policy</Section>
          Supersheets keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          the 21st of March, 2022.
          <Section>How to contact us</Section>
          If you have any questions about our privacy policy, the data we hold
          on you, or you would like to exercise one of your data protection
          rights, please do not hesitate to contact us. Email us at:
          support@supersheets.so
          <Section>How to contact the appropriate authority</Section>
          Should you wish to report a complaint or if you feel that we have not
          addressed your concern in a satisfactory manner, you may contact the
          Information Commissioner's Office at https://www.ico.org.uk/ . We
          would, however, appreciate the chance to deal with your concerns
          before you approach the ICO so please contact us first.
        </div>{" "}
      </div>
    </Container>
  </Layout>
);

export default PrivacyPolicy;

const Section = (props) => (
  <h3 className="font-serif font-bold py-2 md:py-4">{props.children}</h3>
);

const List = (props) => (
  <ul className="list-disc list-inside py-2 md:py-4">{props.children}</ul>
);
